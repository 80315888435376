import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { planPurchaseClick, plansPageView, ppWidgetLoaded } from '@wix/bi-logger-pricing-plans-data/v2';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { getSetupFeeAmount, hasSetupFee } from '@wix/pricing-plans-utils';
import { ControllerFlowAPI, ControllerParams } from '@wix/yoshi-flow-editor';
import { QUERY_REQUEST_LIMIT, REQUEST_FILTER_LIMIT } from '../../constants';
import { TabState } from '../../constants/settings-events';
import { Analytics } from '../../services/analytics';
import { PlansApi } from '../../services/plans';
import { isUuid } from '../../services/uuid-compression';
import { WarmupData } from '../../services/WarmupData';
import { ModalType, noModal } from '../../types/common';
import { PlanListInteractions } from '../../types/PlanListFedops';
import { resolveLocale } from '../../utils';
import { WIDGET_TYPE } from '../../utils/bi';
import { fetchPlanV3, navigateToEcomCheckout, navigateToPlanCustomization } from '../../utils/ecom';
import { is403 } from '../../utils/errors';
import { getOrderedVisiblePlans } from '../../utils/plan-visibility';
import { retry401 } from '../../utils/retry401';
import { captureViewerException } from '../../utils/viewer-errors';
import { Navigation } from '../PackagePicker/controller/Navigation';
import { SettingsReader } from '../PackagePicker/DefaultSettingsAdapter';
import { ListWidgetWithModalProps } from './Widget';

export class PlanListController {
  constructor(
    public setProps: (props: Partial<ListWidgetWithModalProps>) => void,
    protected plansApi: PlansApi,
    protected flowAPI: ControllerFlowAPI,
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected appParams: ControllerParams['controllerConfig']['appParams'],
    protected analytics: Analytics,
    protected settings: SettingsReader,
    protected demoPlans: PublicPlan[],
    protected warmupData: WarmupData,
    protected navigation: Navigation,
  ) {}

  public async initialize() {
    const { plans } = await this.fetchAndOrderPlans();
    this.setProps({
      plans,
      tabState: TabState.REGULAR,
      selectPlan: this.onSelectPlan,
      locale: resolveLocale(this.wixCodeApi),
      modal: noModal,
      widgetLoaded: () =>
        this.flowAPI.bi?.report(
          ppWidgetLoaded({
            widgetType: WIDGET_TYPE.list,
          }),
        ),
    });

    if (this.flowAPI.environment.isViewer) {
      this.flowAPI.bi?.report(
        plansPageView({
          widgetType: WIDGET_TYPE.list,
        }),
      );
    }
    this.analytics.addProductImpression(plans);
  }

  fetchAndOrderPlans = async () => {
    const visiblePlans = this.settings.uuidAsArray('visiblePlans').filter(isUuid);
    let plans: PublicPlan[] = [];
    try {
      const exceedsFilterLimit = visiblePlans.length > REQUEST_FILTER_LIMIT;
      plans = await this.warmupData.cache('widget.plans', () =>
        retry401(this.wixCodeApi, () =>
          exceedsFilterLimit
            ? this.plansApi.query({ limit: QUERY_REQUEST_LIMIT })
            : this.plansApi.query({ include: visiblePlans }),
        ),
      );
    } catch (e) {
      captureViewerException(this.flowAPI, e, {
        additionalIgnorePredicates: [is403],
      });
    }

    const useFixture = !plans?.length && this.wixCodeApi.window.viewMode === 'Editor';

    return { plans: getOrderedVisiblePlans(useFixture ? this.demoPlans : plans, this.settings) };
  };

  private onSelectPlan = async (plan: PublicPlan) => {
    this.flowAPI.fedops.interactionStarted(PlanListInteractions.NavigateToList);
    this.flowAPI.panoramaClient?.transaction(PlanListInteractions.NavigateToList).start();

    this.setProps({ selectedPlanId: plan.id });
    if (this.wixCodeApi.window.viewMode !== 'Site') {
      this.setProps({
        modal: { type: ModalType.PurchaseInPreview, onClose: this.closeModal },
        selectedPlanId: undefined,
      });
    } else if (
      this.flowAPI.experiments.enabled(TPA_EXPERIMENTS.INSTALL_ECOM) ||
      this.flowAPI.experiments.enabled(TPA_EXPERIMENTS.ECOM_INTEGRATION_ENABLED)
    ) {
      const navigateToCustomization = plan.formId || plan.allowFutureStartDate;
      if (navigateToCustomization) {
        navigateToPlanCustomization(this.wixCodeApi, { planId: plan.id!, integrationData: {} });
      } else {
        const planV3 = await fetchPlanV3({ id: plan.id }, this.flowAPI.httpClient);
        await navigateToEcomCheckout({
          plan: planV3,
          wixCodeApi: this.wixCodeApi,
          httpClient: this.flowAPI.httpClient,
        });
      }
    } else {
      await this.navigateToCheckout(plan);
    }
    this.flowAPI.fedops.interactionEnded(PlanListInteractions.NavigateToList);
    this.flowAPI.panoramaClient?.transaction(PlanListInteractions.NavigateToList).finish();
  };

  private closeModal = () => {
    return this.setProps({ modal: noModal });
  };

  private navigateToCheckout = async (plan: PublicPlan) => {
    this.flowAPI.bi?.report(
      planPurchaseClick({
        planGuid: plan.id ?? '',
        widgetType: 'list',
        setupFeeAmount: hasSetupFee(plan) ? Math.round(Number(getSetupFeeAmount(plan)) * 100) : undefined,
      }),
    );
    this.analytics.clickProduct(plan);
    return this.navigation.setCurrentPath({
      name: 'checkout',
      checkoutData: { planId: plan.id!, integrationData: {} },
    });
  };
}
